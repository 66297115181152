import React, { lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { useSelector, useDispatch } from 'react-redux'
import { ensureAuth } from './state/authSlice';
import { isUserAtLeastUserType } from './state/authHelper';

import { library } from '@fortawesome/fontawesome-svg-core'
//import { fas } from '@fortawesome/pro-solid-svg-icons'
//import { fal } from '@fortawesome/pro-light-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'

import { LoadingPage } from './pages/LoadingPage';
import { NavMenu } from './components/NavMenu';
import './custom.css'

import '@progress/kendo-theme-bootstrap/dist/all.css';
import 'react-reflex/styles.css'

library.add(far, fad);

const LoginPage = lazy(() => import('./pages/LoginPage'));
const HomePage = lazy(() => import('./pages/HomePage'));
const SubmitWorkOrderPage = lazy(() => import('./pages/SubmitWorkOrderPage'));

const PdfReviewListPage = lazy(() => import('./pages/PdfReviewListPage'));
const PdfReviewPage = lazy(() => import('./pages/PdfReviewPage'));
const PdfReviewSpotCheckPage = lazy(() => import('./pages/PdfReviewSpotCheckPage'));

const DataEntryListPage = lazy(() => import('./pages/DataEntryListPage'));
const DataEntryPage = lazy(() => import('./pages/DataEntryPage'));

const TiebreakerListPage = lazy(() => import('./pages/TiebreakerListPage'));
const TiebreakerPage = lazy(() => import('./pages/TiebreakerPage'));

const AiListPage = lazy(() => import('./pages/AiListPage'));

const AiReviewListPage = lazy(() => import('./pages/AiReviewListPage'));
const AiReviewPage = lazy(() => import('./pages/AiReviewPage'));

const QueueManagerListPage = lazy(() => import('./pages/QueueManagerListPage'));

const SdsRecordListPage = lazy(() => import('./pages/SdsRecordListPage'));
const SdsRecordPage = lazy(() => import('./pages/SdsRecordPage'));

const UserListPage = lazy(() => import('./pages/UserListPage'));
const UserPage = lazy(() => import('./pages/UserPage'));

const WelcomePage = lazy(() => import('./pages/WelcomePage'));
const ProfilePage = lazy(() => import('./pages/ProfilePage'));

const WorkOrderHistoryPage = lazy(() => import('./pages/WorkOrderHistoryPage'));

const ReportBatchStatusPage = lazy(() => import('./pages/ReportBatchStatusPage'));
const ReportBatchSpotCheckPage = lazy(() => import('./pages/ReportBatchSpotCheckPage'));
const ReportBatchSummaryPage = lazy(() => import('./pages/ReportBatchSummaryPage'));
const BatchWorkOrderPage = lazy(() => import('./pages/BatchWorkOrderPage'));
const BatchListPage = lazy(() => import('./pages/BatchListPage'));
const BatchPage = lazy(() => import('./pages/BatchPage'));
const BatchInvitedUserPage = lazy(() => import('./pages/BatchInvitedUserPage'));


const App = (props) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    

    // todo: this feels like a hack... but we have to guard from calling ensureAuth on every rebind somehow... need to research other patterns
    if (!auth.isFetched) {

        dispatch(ensureAuth());

        return <LoadingPage />

    } else {
        
        if (auth.user.UserLogin !== undefined && auth.user.UserType != "LimitedProcessor") {

            // Routes available to authenticated users of all types except LimitedProcessor

            return (

                <>
                    
                    <NavMenu />

                    <Suspense fallback={<LoadingPage />}>


                        <Switch>

                            <Route exact path='/'>
                                {isUserAtLeastUserType('Manager') ? (
                                    <HomePage />
                                ) : (
                                    <PdfReviewListPage/>
                                )}
                            </Route>

                            <Route exact path='/pdfreview'>
                                <PdfReviewListPage />
                            </Route>

                            <Route exact path='/pdfreview/:workOrderUID'>
                                <PdfReviewPage />
                            </Route> 

                            <Route exact path='/pdfreviewspotcheck/:workOrderUID'>
                                <PdfReviewSpotCheckPage />
                            </Route> 

                            <Route exact path='/workorder/:workOrderUID/history'>
                                <WorkOrderHistoryPage />
                            </Route> 
                            
                            <Route exact path='/dataentry'>
                                <DataEntryListPage />
                            </Route>

                            <Route exact path='/dataentry/:workOrderUID'>
                                <DataEntryPage />
                            </Route>

                            <Route exact path='/tiebreaker'>
                                <TiebreakerListPage />
                            </Route>

                            <Route exact path='/tiebreaker/:workOrderUID'>
                                <TiebreakerPage />
                            </Route>

                            <Route exact path='/aiqueue'>
                                <AiListPage />
                            </Route>
                            
                            <Route exact path='/aireview'>
                                <AiReviewListPage />
                            </Route>

                            <Route exact path='/aireview/:workOrderUID'>
                                <AiReviewPage />
                            </Route>
                            
                            <Route exact path='/queuemanager'>
                                <QueueManagerListPage />
                            </Route>

                            <Route exact path='/sdsrecord'>
                                <SdsRecordListPage />
                            </Route>

                            <Route exact path='/sdsrecord/:sdsRecordUID'>
                                <SdsRecordPage />
                            </Route> 

                            <Route exact path='/submit'>
                                <SubmitWorkOrderPage />
                            </Route> 

                            <Route exact path='/users'>
                                <UserListPage />
                            </Route>

                            <Route exact path="/user/edit/:userUID">
                                <UserPage />
                            </Route>

                            <Route exact path="/user/add">
                                <UserPage />
                            </Route>

                            <Route exact path="/profile">
                                <ProfilePage />
                            </Route>

                            
                            <Route exact path="/aiqueue">
                                <div className="page-header"><h3>Ai Queue</h3></div>
                                <div className="page-body">coming soon</div>
                            </Route>


                            <Route exact path="/aimappings">
                                <div className="page-header"><h3>Ai Mappings</h3></div>
                                <div className="page-body">coming soon</div>
                            </Route>

                            <Route exact path="/batch">
                                <BatchListPage  />
                            </Route>

                            <Route exact path="/batch/:batchUID/edit">
                                <BatchPage />
                            </Route>
                            <Route exact path="/batch/add">
                                <BatchPage />
                            </Route>         
                            <Route exact path="/batch/:batchUID">
                                <BatchWorkOrderPage />
                            </Route>                   
                            <Route exact path="/report/batchstatus">
                                <ReportBatchStatusPage />
                            </Route>
                            <Route exact path="/report/batchspotcheck">
                                <ReportBatchSpotCheckPage />
                            </Route>
                            <Route exact path="/report/batchsummary">
                                <ReportBatchSummaryPage />
                            </Route>

                            <Redirect exact from="/pdfreviewnext" to="/pdfreview/next" />
                            <Redirect exact from="/sdsrecordnext" to="/sdsrecord/next" />
                            <Redirect exact from="/dataentrynext" to="/dataentry/next" />
                            <Redirect exact from="/tiebreakernext" to="/tiebreaker/next" /> 
                            <Redirect exact from="/aireviewnext" to="/aireview/next" /> 


                        </Switch>

                    </Suspense>

                </>

            );

        } else {

            // Routes available to LimitedProcessor (and LoginPage to unauthenticated users)

            return (
                <Suspense fallback={<LoadingPage />}>

                    <Switch>
                        <Route exact path='/welcome/:welcomeCode'>
                            <WelcomePage />
                        </Route>

                        <Route exact path='/welcome'>
                            <WelcomePage />
                        </Route>

                        <Route exact path='/batch/:batchUID/id/:rowUID'>
                            <BatchInvitedUserPage />
                        </Route>

                        <Route exact path='/pdfreview/limited/:workOrderUID'>
                            <PdfReviewPage />
                        </Route> 

                        <Route exact path='/aireview/limited/:workOrderUID'>
                            <AiReviewPage />
                        </Route> 

                        <Route exact path='/sdsrecord'>
                            <SdsRecordListPage />
                        </Route>

                        <Route exact path='/sdsrecord/:sdsRecordUID'>
                            <SdsRecordPage />
                        </Route> 

                        <Route path="*">
                            <LoginPage />
                        </Route>
                    </Switch>

                </Suspense>


            );

        }

    }
};

export default App;
