import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux'
import { logoutAuth } from '../state/authSlice';
import { isUserAtLeastUserType } from '../state/authHelper';
import { setCurrentNavMenu } from '../state/navSlice';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const NavMenu = (props) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const nav = useSelector(state => state.nav);



    const buildNavItem = (href, title, code, icon, minUserType) => {
        if (isUserAtLeastUserType(minUserType)) {
            var className = (nav.currentNavMenu === code ? 'active' : '') + ' nav-link py-3 border-bottom border-top';
            return (
                <li className="nav-item">
                    <OverlayTrigger placement="right" overlay={<Tooltip>{title}</Tooltip>}>
                        <Link
                            to={href}
                            className={className}
                            title={title}
                            onClick={() => dispatch(setCurrentNavMenu(code))}
                            data-bs-toggle="tooltip"
                            data-bs-placement="right">
                            <FontAwesomeIcon icon={['far', icon]} size="lg" />
                        </Link>
                    </OverlayTrigger>
                </li>
            );
        } else {
            return null;
        }
    };

    const buildDropdownItem = (href, title, code, icon, minUserType) => {
        if (isUserAtLeastUserType(minUserType)) {
            return (
                <li>
                    <Link to={href} className="dropdown-item" onClick={() => dispatch(setCurrentNavMenu(code))}>
                        <FontAwesomeIcon icon={['far', icon]} size="sm" fixedWidth={true} /> &nbsp; {title}
                    </Link>
                </li>
            );
        } else {
            return null;
        }
    };

    const buildReportMenu = () => {
        if (isUserAtLeastUserType('Admin')) {
            var className = (nav.currentNavMenu === 'reportmenu' ? 'active ' : '') + 'nav-link py-3 border-bottom border-top cursor-hand dropend';
            return (
                <li className="nav-item">
                    <div className={className}>
                        <OverlayTrigger placement="right" overlay={<Tooltip>Reports</Tooltip>}>
                            <div className="dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                <FontAwesomeIcon icon={['fad', 'abacus']} size="lg" />
                            </div>
                        </OverlayTrigger>
                        <ul className="dropdown-menu text-small shadow">
                            {buildDropdownItem('/report/batchstatus', 'Report - Batch Status', 'reportmenu', 'chart-line', 'Admin')}
                            {buildDropdownItem('/report/batchspotcheck', 'Report - Batch Spot Check', 'reportmenu', 'chart-line', 'Admin')}
                            {buildDropdownItem('/report/batchsummary', 'Report - Batch Summary', 'reportmenu', 'chart-line', 'Admin')}
                        </ul>
                    </div>
                </li>
            );
        } else {
            return null;
        }
    };


    const buildAdminMenu = () => {
        if (isUserAtLeastUserType('Admin')) {
            var className = (nav.currentNavMenu === 'adminmenu' ? 'active ' : '') + 'nav-link py-3 border-bottom border-top cursor-hand dropend';
            return (
                <li className="nav-item">
                    <div className={className}>
                        <OverlayTrigger placement="right" overlay={<Tooltip>Administration</Tooltip>}>
                            <div className="dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                <FontAwesomeIcon icon={['far', 'sliders-h-square']} size="lg" />
                            </div>
                        </OverlayTrigger>
                        <ul className="dropdown-menu text-small shadow">
                            {buildDropdownItem('/batch', 'Batch Management', 'adminmenu', 'layer-group', 'Admin')}
                            <li className="dropdown-divider"/>
                            {buildDropdownItem('/users', 'User Management', 'adminmenu', 'users', 'Admin')}
                            <li className="dropdown-divider"/>
                            {buildDropdownItem('/aimappings', 'Ai Mappings', 'adminmenu', 'project-diagram', 'Admin')}
                            <li className="dropdown-divider" />
                            {buildDropdownItem('/submit', 'Submit Work Order', 'adminmenu', 'file-import', 'Admin')}
                        </ul>
                    </div>
                </li>
            );
        } else {
            return null;
        }
    };



    // const buildDevMenu = () => {
    //     if (isUserAtLeastUserType('Admin')) {
    //         var className = (nav.currentNavMenu === 'devmenu' ? 'active ' : 'text-danger ') + 'nav-link py-3 border-bottom border-top cursor-hand dropend';
    //         return (
    //             <li className="nav-item">
    //                 <div className={className}>
    //                     <div className="dropdown" data-bs-toggle="dropdown" aria-expanded="false">
    //                         <FontAwesomeIcon icon={['far', 'code']} size="lg" />
    //                     </div>
    //                     <ul className="dropdown-menu text-small shadow">
    //                         {buildDropdownItem('/queuemanager', 'Developer Queue Manager', 'devmenu', 'list-alt', 'Admin')}
    //                     </ul>
    //                 </div>
    //             </li>
    //         );
    //     }
    //     else {
    //         return null;
    //     }
    // };



    return (
        <>

            <div className="sidebar">

                <Link to="/" className="d-block pt-3 pb-3 link-primary text-decoration-none text-center w-100 sidebar-header" title="">
                    <FontAwesomeIcon icon={['fad', 'hard-hat']} size="2x" />
                </Link>

                <ul className="nav nav-pills nav-flush flex-column mb-auto text-center">

                    {buildNavItem('/', 'Home', 'home', 'home', 'Manager')}                    
                    {buildNavItem('/pdfreview', 'PDF Review Queue', 'pdfreview', 'file-pdf', 'Processor')}

                    {buildNavItem('/dataentry', 'Data Entry Queue', 'dataentry', 'file-spreadsheet', 'Manager')}
                    {buildNavItem('/tiebreaker', 'Tiebreaker Queue', 'tiebreaker', 'poll-people', 'Manager')}

                    {buildNavItem('/aiqueue', 'Ai Queue', 'aiqueue', 'chart-network', 'Manager')}
                    {buildNavItem('/aireview', 'Ai Review Queue', 'aireview', 'user-ninja', 'Processor')}

                    {buildNavItem('/sdsrecord', 'Library Search', 'sdsrecord', 'search', 'Processor')}
                    
                    {buildReportMenu()}

                    {buildAdminMenu()}
                    
                    {/* {buildDevMenu()} */}
                    {buildNavItem('/queuemanager', 'Developer Queue Manager', 'queuemanager', 'code', 'Admin')}
                    

                </ul>

                <div className="border-top text-center sidebar-lasticon">
                    <OverlayTrigger placement="right" overlay={<Tooltip>Profile and Settings</Tooltip>}>
                        <span className="d-flex align-items-center justify-content-center p-3 link-dark text-decoration-none cursor-hand" data-bs-toggle="dropdown">
                            <FontAwesomeIcon icon={['far', 'user-circle']} size="lg" />
                        </span>
                    </OverlayTrigger>
                    <ul className="dropdown-menu text-small shadow ms-3">
                        <li><div className="dropdown-item disabled">{auth.user.UserLogin}</div></li>
                        <li><hr className="dropdown-divider" /></li>
                        <li><Link className="dropdown-item" to="/profile">Profile</Link></li>
                        <li><Link className="dropdown-item" to='/' onClick={() => dispatch(logoutAuth())}>Sign Out</Link></li>
                    </ul>

                </div>

            </div>



        </>
    );


};
