import React from 'react';

export const Spinner = (props) => {

    return (

        <div className="p-5">
            <div className="index-spinner"></div>
        </div>

    );

}
