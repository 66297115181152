
import store from '../store'

export const isUserAtLeastUserType = (minUserType) => {

    const user = store.getState().auth.user;

    switch (minUserType) {
        case 'Admin':
            return user.UserType === 'Admin';
        case 'Manager':
            return user.UserType === 'Admin' || user.UserType === 'Manager';
        case 'Processor':
            return user.UserType === 'Admin' || user.UserType === 'Manager' || user.UserType === 'Processor';
        case 'LimitedProcessor':
            return user.UserType === 'Admin' || user.UserType === 'Manager' || user.UserType === 'Processor' || user.UserType === 'LimitedProcessor';
        default:
            throw new Error("Unknown MinUserType: " + minUserType);
    }
    
};

